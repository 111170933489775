import React, { useState } from 'react';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';

function randomID(len: number) {
    let result = '';
    if (result) return result;
    var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
      maxPos = chars.length,
      i;
    len = len || 5;
    for (i = 0; i < len; i++) {
      result += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return result;
}

// get token
function generateToken(tokenServerUrl: string, userID: string) {
    // Obtain the token interface provided by the App Server
    return fetch(
      `${tokenServerUrl}/access_token?userID=${userID}&expired_ts=7200`,
      {
        method: 'GET',
      }
    ).then((res) => res.json());
}

export function getUrlParams(
    url: string = window.location.href
  ): URLSearchParams {
    let urlStr = url.split('?')[1];
    return new URLSearchParams(urlStr);
}

export default function VideollamadaMonitorista() {
    const roomID = getUrlParams().get('roomID') || randomID(5);
    const userID =  randomID(5);
    const userName = "Monitorista";  //const userName = randomID(5);
    let myMeeting = async (element: HTMLDivElement) => {
      console.log(NombreUsuario);
      // generate token
      const { token } = await generateToken(
        'https://nextjs-token.vercel.app/api',
        userID
      );
      const kitToken = ZegoUIKitPrebuilt.generateKitTokenForProduction(
        1484647939,
        token, //'04AAAAAGZ/Iq4AEHYyYmIzZjAzdXFmajhyN2MAoDaTWI1bDAiKL4LJyT3fgbmkuCBxXyHOnUKvV+8UxBhhQwdQKKo5nmRBAbpmj1vZh5PVEon3mb5XBuHRMUFmDZTc0kBtWHurowOIUuhM/H3zkL6esbzbZfNzh1a3TGlcPKDvghClHTADoN+eXiXnp0BJ3JZ+VD2mexlZApro2kvHm4Q4FezyzzV6KxFytkmFxYU/ccvgO9UGs6wu6CFt4oM=',  //token,
        '3759',
        userID,
        userName
      );
      // create instance object from token
      const zp = ZegoUIKitPrebuilt.create(kitToken);
      // start the call
      zp.joinRoom({
        container: element,
        sharedLinks: [
          {
            name: 'Personal link',
            url:
              window.location.origin +
              window.location.pathname +
              '?roomID=' +
              roomID,
          },
        ],
        scenario: {
          mode: ZegoUIKitPrebuilt.GroupCall, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
        },
        onLeaveRoom: () => {
            console.log('Finalizo la llamada');
        },
        onUserLeave: () => {
            console.log('abandono la sala');
        }
      });
    };
  
    const [NombreUsuario, CambioNombreUsuario] = useState('');
  
  
    return (
      <div>
        <div
          className="myCallContainer"
          ref={myMeeting}
          style={{ width: '100vw', height: '100vh' }}>
        </div>
      </div>
      
    );
}