import React from 'react';
import { useNavigate } from 'react-router-dom';

const Configuracion: React.FC = () => {
    const navigate = useNavigate();
  
    const handleInformesClick = () => {
      navigate('/informes');
    };
  
    const handleAlarmaClick = () => {
      navigate('/alarma');
    };

    const handleAlertClick = () => {
        navigate('/alerta');
      };
  
    return (
      <div>
        <h2>Panel de Control</h2>
        <div className="button-container">
          <button onClick={handleAlertClick}>En Linea</button>
          <button onClick={handleInformesClick}>Informes</button>
          <button onClick={handleAlarmaClick}>Alarma</button>
        </div>
      </div>
    );
  };
  
  export default Configuracion;