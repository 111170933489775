import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './components/Login/Login';
import Videollamada from './components/Videollamada/Videollamada';
import VideollamadaMonitorista from './components/Videollamada/VideollamadaMonitorista';
import Configuracion from './components/Configuracion/Configuracion';


const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={< Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/videollamada" element={<Videollamada />} />
          <Route path="/videollamadaMonitorista" element={<VideollamadaMonitorista />} />
          <Route path="/Configuracion" element={<Configuracion />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;